import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs';
import baseUrl from '../../../../service';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import ImageSlider from '../partials/imageSlider';
import { userService } from '../../../../service/plateServices';
import { floraServices } from '../../../../service/fileServices/environmentFlora';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';





interface LabReportViewModalProps {
    showView: boolean;
    setShowView: (value: boolean) => void;
    plateId: string;
    apiType: string;
    interfaceName ?: string;
    userId ?: string;
}

const PlateDetailsViewModel: React.FC<LabReportViewModalProps> = ({
    showView,
    setShowView,
    plateId,
    apiType,
    interfaceName,
    userId,
}) => {

    type LabtestStatType = {
        pending_plates_for_lab_approval: number;
        lab_approved_plates: number;
        escalations: number;
      };

  const areaId = useSelector((state: RootState) => state.area.areaId)|| '';
  const [tab, setTab] = useState("1");
//   const [showView, setShowView] = useState(true);
  const [historyView, setHistoryView] = useState(false);
  const [plateDetails, setPlateDetails] = useState<Array<any>>([]);
  const [plateDetailsHistory, setPlateDetailsHistory] = useState<Array<any>>([]);
  const [singlePlateDetails, setSinglePlateDetails] = useState<any>({});
  const [incubationDetails, setIncubationDetails] = useState<any>([]);
  const [exposureDetails, setExposureDetails] = useState<any>([]);
  const [fingureDabDetails, setFingureDabDetails] = useState<any>([]);
  const [plateSamplingDetails, setPlateSamplingDetails] = useState<any>([]);
  const [rawMaterialDetails, setRawMaterialDetails] = useState<any>([]);
  const [surfaceMonitoringDetails, setSurfaceMonitoringDetails] = useState<any>([]);
  const [surfaceMonitoringSwabDetails, setSurfaceMonitoringSwabDetails] = useState<any>([]);
  const [envFloraDetails, setEnvFloraDetails] = useState<any>([]);
  const [exceptionDetails, setExceptionDetails] = useState<any>([]);
  const [deviationDetails, setDeviationDetails] = useState<any>([]);
  const [autoclaveDetails, setautoclaveDetails] = useState<any>([]);

  const [labTestDetails, setLabTestDetails] = useState<any>([]);
  const [useEffectRequired, setUseEffectRequired] = useState(false);
  const [plateBatches, setPlateBatches] = useState([]);
  const [plateSerielNum, setPlateSerielNums] = useState([]);
  const [keyWord, setkeyWord] = useState("");
  const [historyKeyWord, setHistoryKeyWord] = useState("");
  // const [userId, setUserId] = useState<number>(0);
  const [showApproveAlert, setShowApproveAlert] = useState(false)
  const [showRejectAlert, setShowRejectAlert] = useState(false)
  const [currentExposureIndex, setCurrentExposureIndex] = useState(0);
  const [currentSamplingIndex, setCurrentSamplingIndex] = useState(0);
  const [currentRawmaterialTestIndex, setCurrentRawmaterialTestIndex] = useState(0);
  const [currentFingerDabIndex, setCurrentFingerDabIndex] = useState(0);
  const [currentIncubationIndex, setCurrentIncubationIndex] = useState(0);
  const [currentLabTestIndex, setCurrentLabTestIndex] = useState(0);
  const [currentSurfaceMonitoringIndex, setCurrentSurfaceMonitoringIndex] = useState(0);
  const [currentSurfaceMonitoringSwabIndex, setCurrentSurfaceMonitoringSwabIndex] = useState(0);
  const [currentFloraIndex, setCurrentFloraIndex] = useState(0);
  const [currentExceptionIndex, setCurrentExceptionIndex] = useState(0);
  const [currentDeviationIndex, setCurrentDeviationIndex] = useState(0);
  const [labtestStat, setLabTestStat] = useState<LabtestStatType | null>(null);
  const [selectedTab, setSelectedTab] = useState<number | null>(null);
  const [tabValue, setTabValue] = useState("");
  const [autoclaveIndex, setautoclaveIndex] = useState(0);



  // pagination
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  //history pagination
  const [historyPage, setHistoryPage] = useState(1);
  const [historyPageSize, setHistoryPageSize] = useState(5);
  const [historyTotalPages, setHistoryTotalPages] = useState(0);
  const [historyTotalElements, setHistoryTotalElements] = useState(0);

  const [showApproveRejectBtn , setShowApproveRejectBtn] = useState(false);

  const scanPlateById = async (id:any, key:string) => {

    const dataToSend = {
      "id" : id,
      "lab_area_id":areaId
    }
    let response
    if(key === "plateById") {
      response = await userService.scanPlateById({"id": id});
    } else if (key === "floraById") {
      response = await floraServices.scanEnvironmentFloraById(dataToSend);
    }

    // const dataToSend = {
    //   "id" : id
    // }
    // const response = await userService.scanPlateById(dataToSend);
    if(response?.status && response?.statusCode === 200){
      console.log(response, "response--")


      // const plateType = parseInt(response?.data.plate_type, 10); 
      //               if (plateType == 1){
      //                 var plate_type = "Q A Plate"
      //               }else if (plateType == 2){
      //                 var plate_type = "Exposure Plate"
      //               }else if (plateType == 3){
      //                 var plate_type = "Personnel Monitoring Plate"
      //               }else if (plateType == 4){
      //                 var plate_type = "Sampling Plate"
      //               }else if (plateType == 5){
      //                 var plate_type = "Material Test Plate"
      //               }else if (plateType == 6){
      //                 var plate_type = "Surface Monitoring Plate"
      //               }else if (plateType == 7){
      //                 var plate_type = "Surface Monitoring - SWAB Collection"
      //               } else{
      //                 var plate_type = "-"
      //               }

      const statusNumber = parseInt(response?.data?.status, 10); 
                   if (statusNumber == 21){
                    var status = "pending"
                   }else{
                    var status = "unknown"
                   }

      const basicDetails = {
        "id": response.data.id,
        "serial_number": response.data.serial_number,
        "batch_number": response.data.batch_number,
        "plate_status": response.data.plate_status,
        "status": status,
        "expiry_date": response.data.expiry_date,
        "manufacturing_date": response.data.manufacturing_date,
        "current_location_id": response.data.current_location_id,
        "plate_type": response.data.master_activity_name,
      }

      let exposureDetails:any = response.data?.exposure_details?.map((element: any, index: number) => {
        return {
          exposedAreaName : element.exposure_area_name || "-",
          exposedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
          exposedDate : element.start_date_time,
          collectedBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
          collectedDate : element.end_date_time || "-",
        }
      })

      let fingureDabDetails = response.data?.fingerdab_details?.map((element: any, index: number) => {
        return {
          fingerDabAreaName : element.location_name || "-",
          fingerDabbededBy : element.finger_dab_operator_name || "-",
          dabbedDate : element.start_date_time || "-",
          fingerDabType: element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          figerDabOption : element.finger_dab_option_name || "-",
          fingerDabGuest : element.guest_name || "-"
        }
      })

      let plateSamplingDetails:any = response.data?.plate_sampling_details?.map((element: any, index: number) => {
        return {
          plateSamplingType : element.test_type_name || "-",
          sampledBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          sampledDate : element.start_date_time || "-",
        }
      })

      let rawMaterialDetails:any = response.data?.raw_material_test_details?.map((element: any, index: number) => {
        return {
          rawMaterialTypeName : element.material_type_name || "-",
          conductedBy : element.started_by_user_firstname + " " + element.started_by_user_lastname || "-",
          testDate : element.start_date_time || "-",
        }
      })

      let surfaceMonitoringDetails:any = response.data?.surface_monitoring_details?.map((element: any, index: number) => {
        return {
          surfaceMonitoringAreaName : element.location_name || "-",
          surfaceMonitoringStartDate : element.start_date_time || "-",
        }
      })


      let incubationDetails:any = response.data?.incubation_details?.map((element: any, index: number) => {
        return {
          incubationAreaName: element.incubator_name || "-",
          incubatedBy : element.created_by_first_name + " " + element.created_by_last_name || "-",
          incubationDateTime : element.start_date_time || "-",
          incubationOutBy : element.updated_by_first_name + " " + element.updated_by_last_name || "-",
          incubationoutDateTime : element.end_date_time || "-",
        }
      })

      let labTestDetails:any = response.data?.lab_test_details?.map((element: any, index: number) => {
        return {
          test_done_by_user_id: element.test_done_by_user_id,
          conductedBy: element.completed_by_user_firstname + " " + element.completed_by_user_lastname || "-",
          dateAndTime : element.completed_date_time || "-",
          colonyCount : element.colony_count || "-",
          imageId : element.plate_image_id || "",
          id: element.id || "-",
          approver: element.approver_firstname + " " +  element.approver_lastname || "-", 
          approved_date: element.approved_date || "-",
          testRemarks : element.test_remarks || "-"
        }
      })
      const filteredLabTestDetails = interfaceName === 'LabtestArea' 
        ? labTestDetails.filter((item: any) => item.test_done_by_user_id === userId)
        : labTestDetails;

      let envFloraDetails:any = response.data?.env_flora_details?.map((element: any, index: number) => {
        return {
          conductedBy: element.started_user_firstname + " " + element.started_user_lastname || "-",
          dateAndTime : element.start_date_time || "-",
          id: element.id || "-",
          location_name: element.lab_name || "-",
          colonyDetails: element.colony_details || []
        }
      })

      let exception_details:any = response.data?.exception_details?.map((element: any, index: number) => {
        return {
          requestedBy: element.exception_requested_user_firstname + " " + element.exception_requested_user_lastname || "-",
          dateAndTime : element.exception_requested_datetime || "-",
          id: element.id || "-",
          reason: element.reason || "-",
          remarks: element.remarks || "-",
        }
      })

      let deviation_details:any = response.data?.deviation_details?.map((element: any, index: number) => {
        return {
          reportedBy: element.reported_operator_name,
          id: element.id || "-",
          deviationStatus: element.deviation_status || "-",
          areaName: element.area_name || "-",
        }
      })

      let surfaceMonitoringSwabDetails:any = response.data?.surface_monitoring_swab_details?.map((element: any, index: number) => {
        return {
          surfaceMonitoringAreaName : element.location || "-",
          surfaceMonitoringStartDate : element.start_date_time || "-",
          startedBy : element.started_operator_name || "-",
        }
      })

      let autoclave_details:any = response.data?.autoclave_details?.map((element: any, index: number) => {
        return {
          autoClaveAreaName : element.location_name || "-",
          autoclaveStartTime : element.start_time || "-",
          autoclaveEndTime : element.end_time || "-",
          startedBy : element.started_operator_name || "-",
        }
      })


      setSinglePlateDetails(basicDetails)
      setExposureDetails(exposureDetails)
      setFingureDabDetails(fingureDabDetails)
      setPlateSamplingDetails(plateSamplingDetails)
      setRawMaterialDetails(rawMaterialDetails)
      setIncubationDetails(incubationDetails)
      setLabTestDetails(filteredLabTestDetails)
      setSurfaceMonitoringDetails(surfaceMonitoringDetails)
      setEnvFloraDetails(envFloraDetails)
      setExceptionDetails(exception_details)
      setDeviationDetails(deviation_details)
      setSurfaceMonitoringSwabDetails(surfaceMonitoringSwabDetails)
      setautoclaveDetails(autoclave_details)
    }
  }


  const handleNextClick = (currentIndex : number) => {
    if (currentIndex === 1){
      if (currentExposureIndex < exposureDetails?.length - 1) {
        setCurrentExposureIndex(currentExposureIndex + 1);
      }
    } else if (currentIndex === 2) {
      if (currentIncubationIndex < incubationDetails?.length - 1) {
        setCurrentIncubationIndex(currentIncubationIndex + 1);
      }
    } else if (currentIndex === 3) {
      if (currentLabTestIndex < labTestDetails?.length - 1) {
        setCurrentLabTestIndex(currentLabTestIndex + 1);
      }
    } else if (currentIndex === 4) {
      if (currentFingerDabIndex < fingureDabDetails?.length - 1) {
        setCurrentFingerDabIndex(currentFingerDabIndex + 1);
      }
    } else if (currentIndex === 5) {
      if (currentSamplingIndex < plateSamplingDetails?.length - 1) {
        setCurrentSamplingIndex(currentSamplingIndex + 1);
      }
    } else if (currentIndex === 6) {
      if (currentRawmaterialTestIndex < rawMaterialDetails?.length - 1) {
        setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex + 1);
      }
    } else if (currentIndex === 7) {
      if (currentSurfaceMonitoringIndex < surfaceMonitoringDetails?.length - 1) {
        setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex + 1);
      }
    } else if (currentIndex === 8) {
      if (currentFloraIndex < envFloraDetails?.length - 1) {
        setCurrentFloraIndex(currentFloraIndex + 1);
      }
    }else if (currentIndex === 9) {
      if (currentExceptionIndex < exceptionDetails?.length - 1) {
        setCurrentExceptionIndex(currentExceptionIndex + 1);
      }
    }else if (currentIndex === 10) {
      if (currentDeviationIndex < deviationDetails?.length - 1) {
        setCurrentDeviationIndex(currentDeviationIndex + 1);
      }
    }else if (currentIndex === 11) {
      if (currentSurfaceMonitoringSwabIndex < deviationDetails?.length - 1) {
        setCurrentSurfaceMonitoringSwabIndex(currentSurfaceMonitoringSwabIndex + 1);
      }
    }else if (currentIndex === 12) {
      if (autoclaveIndex < autoclaveDetails?.length - 1) {
        setautoclaveIndex(autoclaveIndex + 1);
      }
    }
  };

  const handlePreviousClick = (currentIndex : number) => {
    if (currentIndex === 1){
      if (currentExposureIndex > 0) {
        setCurrentExposureIndex(currentExposureIndex - 1);
      }
    } else if (currentIndex === 2) {
      if (currentIncubationIndex > 0) {
        setCurrentIncubationIndex(currentIncubationIndex - 1);
      }
    } else if (currentIndex === 3) {
      if (currentLabTestIndex > 0) {
        setCurrentLabTestIndex(currentLabTestIndex - 1);
      }
    } else if (currentIndex === 4) {
      if (currentFingerDabIndex > 0) {
        setCurrentFingerDabIndex(currentFingerDabIndex - 1);
      }
    } else if (currentIndex === 5) {
      if (currentSamplingIndex > 0) {
        setCurrentSamplingIndex(currentSamplingIndex - 1);
      }
    } else if (currentIndex === 6) {
      if (currentRawmaterialTestIndex > 0) {
        setCurrentRawmaterialTestIndex(currentRawmaterialTestIndex - 1);
      }
    } else if (currentIndex === 7) {
      if (currentSurfaceMonitoringIndex > 0) {
        setCurrentSurfaceMonitoringIndex(currentSurfaceMonitoringIndex - 1);
      }
    } else if (currentIndex === 8) {
      if (currentFloraIndex > 0) {
        setCurrentFloraIndex(currentFloraIndex - 1);
      }
    } else if (currentIndex === 9) {
      if (currentExceptionIndex > 0) {
        setCurrentExceptionIndex(currentExceptionIndex - 1);
      }
    }else if (currentIndex === 10) {
      if (currentDeviationIndex > 0) {
        setCurrentDeviationIndex(currentDeviationIndex - 1);
      }
    }else if (currentIndex === 11) {
      if (currentSurfaceMonitoringSwabIndex > 0) {
        setCurrentSurfaceMonitoringSwabIndex(currentSurfaceMonitoringSwabIndex - 1);
      }
    }else if (currentIndex === 12) {
      if (autoclaveIndex > 0) {
        setautoclaveIndex(autoclaveIndex - 1);
      }
    }
  };

  const handleClose = () => {
    setShowView(false)
    setSinglePlateDetails({})
    setExposureDetails([])
    setFingureDabDetails([])
    setPlateSamplingDetails([])
    setRawMaterialDetails([])
    setIncubationDetails([])
    setLabTestDetails([])
    setTabValue("")
    setautoclaveDetails([])
  };


  const getItemClass = (value : any) => {
      return `custom-list-group-item ${tabValue === value ? 'selected' : ''}`;
    };


  useEffect(() => {
    if (showView) {
        scanPlateById(plateId, apiType);
    }

  }, [showView, plateId]);

  useEffect(() => {
    if (exposureDetails?.length > 0) setTabValue("1");
    else if (fingureDabDetails?.length > 0) setTabValue("2");
    else if (plateSamplingDetails?.length > 0) setTabValue("3");
    else if (rawMaterialDetails?.length > 0) setTabValue("4");
    else if (surfaceMonitoringDetails?.length > 0) setTabValue("5");
    else if (incubationDetails?.length > 0) setTabValue("6");
    else if (labTestDetails?.length > 0) setTabValue("7");
    else if (envFloraDetails?.length > 0) setTabValue("8");
    else if (exceptionDetails?.length > 0) setTabValue("9");
    else if (deviationDetails?.length > 0) setTabValue("10");
    else if (surfaceMonitoringSwabDetails?.length > 0) setTabValue("11");
    else if (autoclaveDetails?.length > 0) setTabValue("12");
    else setTabValue(""); // Default to an empty string if all arrays are empty
  }, [
    exposureDetails,
    fingureDabDetails,
    plateSamplingDetails,
    rawMaterialDetails,
    surfaceMonitoringDetails,
    incubationDetails,
    labTestDetails,
    envFloraDetails,
    exceptionDetails,
    deviationDetails,
    surfaceMonitoringSwabDetails,
    autoclaveDetails
  ]);

   
    

  return (
    <Modal className="view-modal" show={showView} size="lg">
        <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title className="view-header">View</Modal.Title>
        </Modal.Header>
        <Modal.Body className="view-body">
            <div className="view-body-content">
                <p className="plate-details">Plate Details</p>
                <div className="view-body-details">
                    <div className="row">
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Serial No</p>
                            <p className="plate-detail-value">{singlePlateDetails?.serial_number}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Batch Code</p>
                            <p className="plate-detail-value">{singlePlateDetails?.batch_number}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Manufacturing Date</p>
                            <p className="plate-detail-value">{singlePlateDetails?.manufacturing_date}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Plate Expiry date</p>
                            <p className="plate-detail-value">{singlePlateDetails?.expiry_date}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="plate-detail-key">Activity Type</p>
                            <p className="plate-detail-value">{singlePlateDetails?.plate_type}</p>
                        </div>
                        {/* <div className="col-md-2">
                            <p className="plate-detail-key">Verified By</p>
                            <p className="plate-detail-value">QA1 & QA2</p>
                        </div>                            */}
                    </div>
                </div>
            </div>
            <div className="view-lab-details">
                <div className="view-lab-details-content">
                    <div className="row">
                        <ul className="custom-list-group col-md-3">
                          {exposureDetails?.length > 0 && (
                            <li className={getItemClass("1")} onClick={() => setTabValue("1")}>Exposure Plate</li>
                          )}
                          {fingureDabDetails?.length > 0 && (
                            <li className={getItemClass("2")} onClick={() => setTabValue("2")}>Personnel Monitoring Plate</li>
                          )}
                          {plateSamplingDetails?.length > 0 && (
                            <li className={getItemClass("3")} onClick={() => setTabValue("3")}>Sampling Plate</li>
                          )}
                          {rawMaterialDetails?.length > 0 && (
                            <li className={getItemClass("4")} onClick={() => setTabValue("4")}>Material Test Plate</li>
                          )}
                          {surfaceMonitoringDetails?.length > 0 && (
                            <li className={getItemClass("5")} onClick={() => setTabValue("5")}>Surface Monitoring Plate</li>
                          )}
                          {surfaceMonitoringSwabDetails?.length > 0 && (
                            <li className={getItemClass("11")} onClick={() => setTabValue("11")}>Surface Monitoring Swab Plate</li>
                          )}
                          {incubationDetails?.length > 0 && (
                            <li className={getItemClass("6")} onClick={() => setTabValue("6")}>Incubation Details</li>
                          )}
                          {labTestDetails?.length > 0 && (
                            <li className={getItemClass("7")} onClick={() => setTabValue("7")}>Lab Details</li>
                          )}
                          {envFloraDetails?.length > 0 && (
                            <li className={getItemClass("8")} onClick={() => setTabValue("8")}>Environmental Flora</li>
                          )}
                          {exceptionDetails?.length > 0 && (
                            <li className={getItemClass("9")} onClick={() => setTabValue("9")}>Exception Details</li>
                          )}
                          {deviationDetails?.length > 0 && (
                            <li className={getItemClass("10")} onClick={() => setTabValue("10")}>Deviation Details</li>
                          )}                         
                          {autoclaveDetails?.length > 0 && (
                            <li className={getItemClass("12")} onClick={() => setTabValue("12")}>Autoclave Details</li>
                          )}
                        </ul>

                        {exposureDetails &&  tabValue === "1" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Exposer Details</p>
                                        <div>
                                            <button 
                                            className={currentExposureIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(1)} 
                                            disabled={currentExposureIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentExposureIndex === exposureDetails?.length - 1 || exposureDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(1)} 
                                            disabled={currentExposureIndex === exposureDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Exposed Area Name</p>
                                        <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedAreaName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Exposed By</p>
                                        <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedBy || "-"}</p>
                                    </div>   
                                    <div className="col-md-4">                  
                                        <p className="plate-detail-key">Exposed Date & Time</p>
                                        <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.exposedDate || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Collected By</p>
                                        <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.collectedBy || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Collected Date & Time</p>
                                        <p className="plate-detail-value">{exposureDetails[currentExposureIndex]?.collectedDate || "-"}</p>                                                     
                                    </div>
                                </div>    
                            </div>
                        )}
                        {fingureDabDetails &&  tabValue === "2" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Personnel Monitoring Details</p>
                                        <div>
                                            <button 
                                            className={currentFingerDabIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(4)} 
                                            disabled={currentFingerDabIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentFingerDabIndex === fingureDabDetails?.length - 1 || fingureDabDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(4)} 
                                            disabled={currentFingerDabIndex === fingureDabDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                          
                                        <p className="plate-detail-key">FingerDab Area Name</p>
                                        <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabAreaName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">FingerDabbed By</p>
                                        <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabbededBy || "-"}</p>
                                    </div> 
                                    <div className="col-md-4">                     
                                        <p className="plate-detail-key">FingerDabbed Date & Time</p>
                                        <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.dabbedDate || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">FingerDab Started By</p>
                                        <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabType || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">FingerDab Option</p>
                                        <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.figerDabOption || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">FingerDab Guest</p>
                                        <p className="plate-detail-value">{fingureDabDetails[currentFingerDabIndex]?.fingerDabGuest || "-"}</p>
                                    </div>
                                </div>    
                            </div>
                        )}
                        {plateSamplingDetails  && tabValue === "3" &&(
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Sampling Details</p>
                                        <div>
                                            <button 
                                            className={currentSamplingIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(5)} 
                                            disabled={currentSamplingIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSamplingIndex === plateSamplingDetails?.length - 1 || plateSamplingDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(5)} 
                                            disabled={currentSamplingIndex === plateSamplingDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Plate Sampling Type</p>
                                        <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.plateSamplingType || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Plate Sampled By</p>
                                        <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.sampledBy || "-"}</p>
                                    </div>  
                                    <div className="col-md-4">                  
                                        <p className="plate-detail-key">Plate Sampled Date & Time</p>
                                        <p className="plate-detail-value">{plateSamplingDetails[currentSamplingIndex]?.sampledDate || "-"}</p>
                                    </div>
                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{plateSamplingDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                        {rawMaterialDetails &&  tabValue === "4" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Material Test Details</p>
                                        <div>
                                            <button 
                                            className={currentRawmaterialTestIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(6)} 
                                            disabled={currentRawmaterialTestIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentRawmaterialTestIndex === rawMaterialDetails?.length - 1 || rawMaterialDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(6)} 
                                            disabled={currentRawmaterialTestIndex === rawMaterialDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Raw Material Name</p>
                                        <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.rawMaterialTypeName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Raw Material Test Conducted By</p>
                                        <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.conductedBy || "-"}</p>
                                    </div>   
                                    <div className="col-md-4">                
                                        <p className="plate-detail-key">Raw Material Test Date & Time</p>
                                        <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.testDate || "-"}</p>
                                    </div>
                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                         {surfaceMonitoringDetails && tabValue === "5" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Surface Monitoring</p>
                                        <div>
                                            <button 
                                            className={currentSurfaceMonitoringIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(7)} 
                                            disabled={currentSurfaceMonitoringIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSurfaceMonitoringIndex === surfaceMonitoringDetails?.length - 1 || rawMaterialDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(7)} 
                                            disabled={currentSurfaceMonitoringIndex === surfaceMonitoringDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Surface Monitoring Area Name</p>
                                        <p className="plate-detail-value">{surfaceMonitoringDetails[currentSurfaceMonitoringIndex]?.surfaceMonitoringAreaName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Surface Monitoring Start Date</p>
                                        <p className="plate-detail-value">{surfaceMonitoringDetails[currentSurfaceMonitoringIndex]?.surfaceMonitoringStartDate || "-"}</p>
                                    </div>                     
                                    {/* <p className="plate-detail-key">Raw Material Test Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentRawmaterialTestIndex]?.testDate || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected By</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedBy || "-"}</p> */}

                                    {/* <p className="plate-detail-key">Collected Date & Time</p>
                                    <p className="plate-detail-value">{rawMaterialDetails[currentFingerDabIndex]?.collectedDate || "-"}</p>                                                      */}
                                </div>    
                            </div>
                        )}
                         {envFloraDetails && tabValue === "8" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Environmental Flora</p>
                                        <div>
                                            <button 
                                            className={currentFloraIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(8)} 
                                            disabled={currentFloraIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentFloraIndex === envFloraDetails?.length - 1 || envFloraDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(8)} 
                                            disabled={currentFloraIndex === envFloraDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Conducted By</p>
                                        <p className="plate-detail-value">{envFloraDetails[currentFloraIndex]?.conductedBy || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Conducted Date</p>
                                        <p className="plate-detail-value">{envFloraDetails[currentFloraIndex]?.dateAndTime || "-"}</p>
                                    </div>   
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Location Name</p>
                                        <p className="plate-detail-value">{envFloraDetails[currentFloraIndex]?.location_name || "-"}</p>                             
                                    </div>
                                        <table className="table" >
                                        <thead>
                                          <tr style={{"fontSize" : "14px"}}>
                                            <th scope="col">Genus</th>
                                            <th scope="col">Species</th>
                                            <th scope="col">Strain</th>
                                            <th scope="col">Colony Type</th>
                                            <th scope="col">Organism Type</th>
                                            <th scope="col">Description</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {envFloraDetails[currentFloraIndex]?.colonyDetails.map((obj : any) => (
                                          <tr style={{"fontSize": "11px"}}>
                                            <td>{obj.genus_name}</td>
                                            <td>{obj.species_name}</td>                                           
                                            <td>{obj.strain_name}</td>
                                            <td>{obj.colony_type_name}</td>
                                            <td>{obj.organism_type_name}</td>
                                            <td>{obj.description}</td>
                                          </tr>
                                        ))}
                                        </tbody>
                                        </table>
                                </div>    
                            </div>
                        )}
                         {exceptionDetails && tabValue === "9" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Exception Details</p>
                                        <div>
                                            <button 
                                            className={currentExceptionIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(9)} 
                                            disabled={currentExceptionIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentExceptionIndex === exceptionDetails?.length - 1 || exceptionDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(9)} 
                                            disabled={currentExceptionIndex === exceptionDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Requested By</p>
                                        <p className="plate-detail-value">{exceptionDetails[currentExceptionIndex]?.requestedBy || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Requested Date</p>
                                        <p className="plate-detail-value">{exceptionDetails[currentExceptionIndex]?.dateAndTime || "-"}</p>
                                    </div>   
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Reason</p>
                                        <p className="plate-detail-value">{exceptionDetails[currentExceptionIndex]?.reason || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Remark</p>
                                        <p className="plate-detail-value">{exceptionDetails[currentExceptionIndex]?.remarks || "-"}</p>                             
                                    </div>
                                </div>    
                            </div>
                        )}
                         {deviationDetails && tabValue === "10" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Deviation Details</p>
                                        <div>
                                            <button 
                                            className={currentDeviationIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(10)} 
                                            disabled={currentDeviationIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentDeviationIndex === deviationDetails?.length - 1 || deviationDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(10)} 
                                            disabled={currentDeviationIndex === deviationDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Reported By</p>
                                        <p className="plate-detail-value">{deviationDetails[currentDeviationIndex]?.reportedBy || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Deviation Status</p>
                                        <p className="plate-detail-value">{deviationDetails[currentDeviationIndex]?.deviationStatus || "-"}</p>
                                    </div>   
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Area Name</p>
                                        <p className="plate-detail-value">{deviationDetails[currentDeviationIndex]?.areaName || "-"}</p>                             
                                    </div>
                                </div>    
                            </div>
                        )}
                        {surfaceMonitoringSwabDetails && tabValue === "11" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Surface Monitoring Swab</p>
                                        <div>
                                            <button 
                                            className={currentSurfaceMonitoringSwabIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(11)} 
                                            disabled={currentSurfaceMonitoringSwabIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentSurfaceMonitoringSwabIndex === surfaceMonitoringSwabDetails?.length - 1 || rawMaterialDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(11)} 
                                            disabled={currentSurfaceMonitoringSwabIndex === surfaceMonitoringSwabDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Surface Monitoring Area Name</p>
                                        <p className="plate-detail-value">{surfaceMonitoringSwabDetails[currentSurfaceMonitoringSwabIndex]?.surfaceMonitoringAreaName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Surface Monitoring Start Date</p>
                                        <p className="plate-detail-value">{surfaceMonitoringSwabDetails[currentSurfaceMonitoringSwabIndex]?.surfaceMonitoringStartDate || "-"}</p>
                                    </div>  
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Started By</p>
                                        <p className="plate-detail-value">{surfaceMonitoringSwabDetails[currentSurfaceMonitoringSwabIndex]?.startedBy || "-"}</p>
                                    </div>                    
                                </div>    
                            </div>
                        )}
                        {tabValue === "6" && (
                            <div className="col-md-3">
                                <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Incubation Details</p>
                                        <div>
                                            <button 
                                            className={currentIncubationIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(2)} 
                                            disabled={currentIncubationIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentIncubationIndex === incubationDetails?.length - 1 || incubationDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(2)} 
                                            disabled={currentIncubationIndex === incubationDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div> 
                                <div className="detail-col-1">
                                    <div className="row">
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Incubation Area Name</p>
                                        <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationAreaName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Incubated By</p>
                                        <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubatedBy || "-"}</p>
                                    </div>           
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Incubation Date & Time</p>
                                        <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationDateTime || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Incubation Stopped By</p>
                                        <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationOutBy || "-"}</p>
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Incubation out Date & Time</p>
                                        <p className="plate-detail-value">{incubationDetails[currentIncubationIndex]?.incubationoutDateTime || "-"}</p>             
                                    </div>
                                    </div>
                                </div>                           
                            </div>
                        )}
                        {tabValue === "7" && (
                            <div className="col-md-6">
                                <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Lab Details</p>
                                        <div>
                                            <button 
                                            className={currentLabTestIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            title='Click here to view Details'
                                            onClick={() => handlePreviousClick(3)} 
                                            disabled={currentLabTestIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={currentLabTestIndex === labTestDetails?.length - 1 || labTestDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'}
                                            title='Click here to view Details' 
                                            onClick={() => handleNextClick(3)} 
                                            disabled={currentLabTestIndex === labTestDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>
                                <div className="detail-col-3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Lab Test Conducted by</p>
                                        <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.conductedBy || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Lab Test Date & Time</p>
                                        <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.dateAndTime || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Colony Count</p>
                                        <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.colonyCount || "-"}</p>                             
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Approver Name</p>
                                        <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.approver || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Approved Date</p>
                                        <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.approved_date || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Remarks</p>
                                        <p className="plate-detail-value">{labTestDetails[currentLabTestIndex]?.testRemarks || "-"}</p>                             
                                    </div>
                                </div>
                                {labTestDetails[currentLabTestIndex]?.imageId && (
                                    <>
                                    <p className="plate-detail-key">Image</p>
                                    <div className='view-image-count-wrap'>
                                        <ImageSlider baseUrl={baseUrl} imageIds={labTestDetails[currentLabTestIndex]?.imageId || ""} />
                                    </div>
                                    </>
                                )}
                                </div>
                            </div>
                        )}
                        {autoclaveDetails && tabValue === "12" && (
                            <div className="col-md-3"> 
                                    <div className="d-flex justify-content-between">
                                        <p className="lab-detail-key">Autoclave Details</p>
                                        <div>
                                            <button 
                                            className={autoclaveIndex === 0 ? 'dishowLeftBtn' : 'showLeftBtn'} 
                                            onClick={() => handlePreviousClick(12)} 
                                            disabled={autoclaveIndex === 0}>
                                                <BsChevronDoubleLeft />
                                            </button>
                                            <button 
                                            className={autoclaveIndex === autoclaveDetails?.length - 1 || autoclaveDetails?.length < 1 ? 'dishowLeftBtn mx-2' : 'showRightBtn mx-2'} 
                                            onClick={() => handleNextClick(12)} 
                                            disabled={autoclaveIndex === autoclaveDetails?.length - 1}>
                                                <BsChevronDoubleRight />
                                            </button>
                                        </div>
                                    </div>                               
                                    <div className="detail-col-1 row">
                                    <div className="col-md-4">                        
                                        <p className="plate-detail-key">Autoclave Area Name</p>
                                        <p className="plate-detail-value">{autoclaveDetails[autoclaveIndex]?.autoClaveAreaName || "-"}</p>                             
                                    </div>
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Autoclave Start Date</p>
                                        <p className="plate-detail-value">{autoclaveDetails[autoclaveIndex]?.autoclaveStartTime || "-"}</p>
                                    </div>  
                                    <div className="col-md-4">
                                        <p className="plate-detail-key">Autoclave End Date</p>
                                        <p className="plate-detail-value">{autoclaveDetails[autoclaveIndex]?.autoclaveEndTime || "-"}</p>
                                    </div>                    
                                </div>    
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
        <Button className="footerCancelBtn" variant="tertiory" onClick={handleClose}>
        Cancel
        </Button>              
        <div>
        {showApproveRejectBtn && (
            <>
                <Button className="footerApproveBtn" onClick={() => setShowApproveAlert && setShowApproveAlert(true)}>
                    Approve
                </Button>
                <Button className="footerRejectBtn" onClick={() => setShowRejectAlert && setShowRejectAlert(true)}>
                    Reject
                </Button>
            </>
        )}
        </div>
        </Modal.Footer>
    </Modal>
  )
}

export default PlateDetailsViewModel;