import React, { useState, useEffect } from 'react'
import {BsQrCodeScan} from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { userService } from '../../../service/plateServices';
import { Modal } from 'react-bootstrap';
import { TfiClose } from 'react-icons/tfi';
import QrReader from 'react-qr-reader';
import AlertModal from '../../../widgets/alertModal';
import CustomToast from '../../../widgets/toaster';
import { AddExceptionModel, ScanAreaModel } from './models';
import { useNavigate } from 'react-router-dom';



interface AddExceptionProps {
    showModel: boolean;
    setShowModel: () => void;
    setRefreshPage?: (() => void) | undefined;
    isPlateSampling?: boolean;
    isRawMaterialTest?: boolean;
  }

const AddException: React.FC<AddExceptionProps> = ({
    showModel,
    setShowModel,
    setRefreshPage,
    isPlateSampling,
    isRawMaterialTest,
}) => {

    const [showAddExceptionAlert, setShowAddExceptionAlert] = useState(false)
    const [showPlateSamplingAlert, setPlateSamplingAlert] = useState(false)
    const [showRawMaterialAlert, setRawMaterialAlert] = useState(false)
    const [plateBySerielNo, setPlateBySerielNo] = useState<Array<any>>([]);
    const [exceptionReasons, setExceptionReasons] = useState<Array<any>>([]);
    const [samleTestType, setSampleTestType] = useState<Array<any>>([]);
    const [rawMaterialType, setRawMaterialType] = useState<Array<any>>([]);
    const [useEffectRequired, setUseEffectRequired] = useState(false);
    const [addExceptionData, setAddExceptionData] = useState({
      remarks: "",
      reason_id: "",
      other_reason: "",
      user_id : 0,
      area_id : "",
      subCategory_id: ""
    });
    const [rawMaterialSub , setRawMaterialSub]= useState<Array<any>>([]);
    const [userId, setUserId] = useState<number>(0);
    const [authToken , setAuthToken] = useState("")
    const [authUserID , setAuthUserID] = useState("")
    interface Errors {
      remarks?: string;
      reason_id?: string;
      other_reason?: string;
      scan_plate?: string;

    }
    const [errors, setErrors] = useState<Errors>({})
    const [webcamResult, setWebCamResult] = useState<string | null>(null);
    const [showScan, setShowScan] = useState(false);
    const [stream, setStream] = useState(false);
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState("")
    const [toastType, setToastType] = useState("")
    const [videoDimensions, setVideoDimensions] = useState<{
      width: number;
      height: number;
    } | null>(null);
    const areaId = useSelector((state: RootState) => state.area.areaId);

    const navigate = useNavigate()

    //get logged in user details
    useEffect(() => {
        var storedData = localStorage.getItem('userDetails');
    
        if(storedData) {
          var storedObject = JSON.parse(storedData);
          setUserId(storedObject[0].id)
          setAuthToken(storedObject[0].x_auth_token)
          setAuthUserID(storedObject[0].x_userid)
        }
        getExceptionReasons();
        getSamplingTestType();
        getRawMaterialType();
      }, [useEffectRequired])

    //get plate details by serial number on add exception 
    const getPlateDetailsBySerielNo = async (slNo : string) => {
      let process = ""
      if (isPlateSampling) {
        process = "plateSampling"
      } else if (isRawMaterialTest) {
        process = "sampleType"
      } else {
        process = "addException"
      }
      
      if(slNo !== ""){
        console.log("have slno")
        try {
          const response = await userService.listAutoClavePlateBySerielNo(slNo, process);
          if(response.status && response.statusCode === 200){
            try {
                // if ( Array.isArray(response.data) && response.data?.length > 0) {
                //   let rowData: any = response.data.map((element: any, index: number) => {
                //     return { 
                //         plateSerielId: element.id ,
                //         plateType: userService.getPlateStatus(parseInt(element.plate_status)) ,
                //         batchId:element.batch_number ,
                //         plateSerielNo: element.serial_number ,
                //     };
                //   });
                if (response.data && typeof response.data === 'object') {
                  let rowData = [{
                      plateSerielId: response.data.id,
                      plateType: userService.getPlateStatus(parseInt(response.data.plate_status)),
                      batchId: response.data.batch_number,
                      plateSerielNo: response.data.serial_number,
                  }];
                  // setPlateBySerielNo(rowData);
                  const uniqueUpdatedDataWithSlno = rowData.filter((newElement : any) => {
                    return !plateBySerielNo.some((existingElement) => existingElement.plateSerielNo === newElement.plateSerielNo);
                  });

                  setPlateBySerielNo((prevUserRoleList) => [...prevUserRoleList, ...uniqueUpdatedDataWithSlno]);
                }
              } catch (error) {
                console.log(error); 
              }
          } else {
            // setPlateBySerielNo([]);
            setToastMessage(response.message);
            setToastType("error")
            setShowToast(true)
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      } else {
      console.log("no slno")
      }
    }
    
    //dropdown to show the exception reason 
      const getExceptionReasons = async () => {
        try {
          const response = await userService.getExceptionReasons();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    if(element.status === "active"){
                      return { 
                          id: element.id ,
                          reason: element.reason ,
                          status: element.status ,
                      };
                    }});
                  setExceptionReasons(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }

        //dropdown to show the samplng test type
        const getSamplingTestType = async () => {
          try {
            const response = await userService.getSamplingTestType();
            if(response.status && response.statusCode === 200){
              try {
                  if ( Array.isArray(response.data) && response.data?.length > 0) {
                    let rowData: any = response.data.map((element: any, index: number) => {
                      if(element.status === "active"){
                        return { 
                            id: element.id ,
                            test_type: element.test_type ,
                            status: element.status ,
                        };
                      }});
                      setSampleTestType(rowData);
                  }
                } catch (error) {
                  console.log(error); 
                }
            }
          } catch (error) {
            console.error('Plate response', error);
          }
        }

      //dropdown to show the raw material type
      const getRawMaterialType = async () => {
        try {
          const response = await userService.getRawMaterialType();
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    if(element.status === "active"){
                      return { 
                          id: element.id ,
                          material_type: element.material_type ,
                          status: element.status ,
                      };
                    }});
                    setRawMaterialType(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }

      //dropdown to show the raw material type
       const getRawMaterialSubCtegory = async (material_id : string) => {
        try {
          const response = await userService.getRawMaterialSubCtegory(material_id);
          if(response.status && response.statusCode === 200){
            try {
                if ( Array.isArray(response.data) && response.data?.length > 0) {
                  let rowData: any = response.data.map((element: any, index: number) => {
                    if(element.status === "active"){
                      return { 
                          id: element.id ,
                          material_type: element.name ,
                          status: element.status ,
                      };
                    }});
                    setRawMaterialSub(rowData);
                }
              } catch (error) {
                console.log(error); 
              }
          }
        } catch (error) {
          console.error('Plate response', error);
        }
      }
    
      //handle exception on change events 
      const handleAddExceptionChange = (e : any) => {
        const { name, value } = e.target;

        let regex;
        if (name === "remarks") {
          regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
        } else if (name === "other_reason") {
          regex = /^[a-zA-Z][a-zA-Z0-9]*$/;
        }

        if (value === "") {
          // console.log("haa122")
        } else if (regex && !regex.test(value)) {
          return
        }

        setAddExceptionData((prevFormData : any) => ({
          ...prevFormData,
          [name]: value,
        }));

        if( name == 'reason_id'){
          setRawMaterialSub([])
          getRawMaterialSubCtegory(value)
        }
      };
    
      //handle exception alerts 
      const handleExceptionAlert = () => {
        const errors: Errors = {};
        if (plateBySerielNo.length === 0 ) {
          errors.scan_plate = "Please scan plate";
        }
        if (!isPlateSampling && !isRawMaterialTest && !addExceptionData.remarks) {
          errors.remarks = "Please enter remark";
        }
        if (!isRawMaterialTest && !isPlateSampling && !addExceptionData.remarks) {
          errors.remarks = "Please enter remark";
        }
        if (!addExceptionData.reason_id || addExceptionData.reason_id === "select") {
          errors.reason_id = "Please select the Sampling Type.";
        }if (!isPlateSampling && addExceptionData.reason_id === "0" && !addExceptionData.other_reason) {
          errors.other_reason = "Please enter Other Reason.";
        }
    
        // If there are any errors, display them to the user and disable the submit button.
        if (Object.keys(errors).length > 0) {
          console.log(errors, "errors")
          setErrors(errors);
          return;
        }
        if (isPlateSampling) {
          setPlateSamplingAlert(true)
        } else if (isRawMaterialTest) {
          setRawMaterialAlert(true)
        } else {
          setShowAddExceptionAlert(true)
        }
        setErrors({});
      }
    
      //handle exception submit api call 
      const handleExceptionSubmit = async () => {
        const dataToSend = {
          "user_id" : userId,
          "plate_ids" : plateBySerielNo.map((plate: any) => plate.plateSerielId).join(','),
          "area_id" : areaId ? areaId : "0",
          "remarks" : addExceptionData.remarks,
          "reason_id" : addExceptionData.reason_id,
          "other_reason" : addExceptionData.other_reason,
          "interface_name" : "Add Exception"
        }
    
        try {
          const response = await userService.addPlateException(dataToSend , authToken , authUserID );
          if(response.status && response.statusCode === 200){
            setUseEffectRequired(true)
            setToastType("success")
            setToastMessage(response.message)
          } else {
            setToastType("error")
            setToastMessage(response.message)
          }
          if (setRefreshPage) {
            setRefreshPage();
          }
          setShowModel()
          setShowAddExceptionAlert(false)
          setShowToast(true)
          setPlateBySerielNo([])
          setErrors({})
        } catch (error) {
          console.error('Plate response', error);
        }
        // setUseEffectRequired(true)
        // setShowModel()
        // setShowAddExceptionAlert(false)
        // setShowToast(true)
        // setPlateBySerielNo([])
        // setErrors({})
    
    
        setAddExceptionData({
          remarks: "",
          reason_id: "",
          other_reason: "",
          user_id : 0,
          area_id : "",
          subCategory_id: ""
        });
      }

      //handle plate sampling submit api call 
      const handlePlateSamplingSubmit = async () => {
        const dataToSend = {
          "user_id" : userId,
          "plate_ids" : plateBySerielNo.map((plate: any) => plate.plateSerielId).join(','),
          "test_type" : addExceptionData.reason_id,
          "interface_name" : "Add Plate Sampling"
        }
    
        try {
          const response = await userService.assignSamplingPlates(dataToSend);
          if(response.status && response.statusCode === 200){
            setUseEffectRequired(true)
            setToastType("success")
            setToastMessage(response.message)
            setShowToast(true)
            addExceptionClose()
            setPlateSamplingAlert(false)
            // navigate("/scanArea")
          } else {
            setToastType("error")
            setToastMessage(response.message)
            setShowToast(true)
            // addExceptionClose()
            // setPlateSamplingAlert(false)

          }
          // setShowModel()
          // setPlateSamplingAlert(false)
          // setShowToast(true)
          // addExceptionClose()
          // setPlateBySerielNo([])
          // setErrors({})
        } catch (error) {
          console.error('Plate response', error);
        }
      }

      //handle rawmaterial test submit api call 
      const handleRawMaterialTestSubmit = async () => {
        const dataToSend = {
          "user_id" : userId,
          "plate_ids" : plateBySerielNo.map((plate: any) => plate.plateSerielId).join(','),
          "raw_material_type" : addExceptionData.reason_id,
          "raw_material_sub_type" : addExceptionData.subCategory_id,
          "interface_name" : "Add Raw Material"
        }
    
        try {
          const response = await userService.assignRawMaterialTest(dataToSend);
          if(response.status && response.statusCode === 200){
            setUseEffectRequired(true)
            setToastType("success")
            setToastMessage(response.message)
            setShowToast(true)
            addExceptionClose()
            setRawMaterialAlert(false)
          } else {
            setToastType("error")
            setToastMessage(response.message)
            setShowToast(true)
            addExceptionClose()
            setRawMaterialAlert(false)

          }
          // setShowModel()
          // setPlateSamplingAlert(false)
          // setShowToast(true)
          // addExceptionClose()
          // setPlateBySerielNo([])
          // setErrors({})
        } catch (error) {
          console.error('Plate response', error);
        }
      }
  
      //on close the add exception modal 
      const addExceptionClose = () => {
        setShowModel()
        setPlateBySerielNo([])
        setErrors({})
        setAddExceptionData({
          remarks: "",
          reason_id: "",
          other_reason: "",
          user_id : 2,
          area_id : "",
          subCategory_id: ""
        });
      }
    
      //handle error in the webcam scan 
      const webCamError = (error: any) => {
        console.log(error);
      };
    
      //handle scanned result from webcam 
      const webCamScan = (result: any) => {
        if (result) {
          const scanData = JSON.parse(result);
          setWebCamResult(result);
          setShowScan(false);
          closeCamera();
          if(scanData?.serial_number){
            setToastMessage("plate scan successfull");
            setToastType("success");
          }else{
            setToastMessage("plate scan Failed");
            setToastType("error");
          }
          setShowToast(true)
          getPlateDetailsBySerielNo(scanData?.serial_number)
        }
      };
    
      //handle modal open for qr scan 
      const handleModalOpen = () => {
        setWebCamResult("");
        setShowScan(true);
        openCamera();
      };
    
      //activate the camera to capture the qr value 
      const openCamera = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          const track = stream.getVideoTracks()[0];
    
          if (track) {
            const settings = track.getSettings();
            const width = settings.width || 0;
            const height = settings.height || 0;
            setVideoDimensions({ width, height });
            setStream(true);
          } else {
            console.error("Video track not found.");
          }
        } catch (error) {
          console.error(error);
        }
      };
    
      //on closing webcam modal 
      const closeCamera = () => {
        // Access the MediaStream from the video element
        const videoElement = document.querySelector('video');
        const mediaStream = videoElement?.srcObject;
      
        if (mediaStream instanceof MediaStream) {
          // Get the tracks and stop them if the mediaStream exists
          const tracks = mediaStream.getTracks();
      
          tracks.forEach((track) => track.stop());
      
          // Close the camera
          setStream(false);
        } else {
          console.error("Video element or media stream not found.");
        }
      };

  return (
    <div>
        <CustomToast
            show={showToast}
            onClose={() => setShowToast(false)}
            delay={5000}
            message={toastMessage}
            toastType={toastType}
            // toastPosition='topLeft'
        /> 

        {/* add exception modal */}
        <AddExceptionModel
                show={showModel}
                plateBySerielNo={plateBySerielNo}
                setPlateBySerielNo={setPlateBySerielNo}
                errors={errors}
                addExceptionData={addExceptionData}
                exceptionReasons={exceptionReasons}
                samleTestType={samleTestType}
                rawMaterialType={rawMaterialType}
                rawMaterialSub={rawMaterialSub}
                addExceptionClose={addExceptionClose}
                getPlateDetailsBySerielNo={getPlateDetailsBySerielNo}
                handleModalOpen={handleModalOpen}
                handleAddExceptionChange={handleAddExceptionChange}
                handleExceptionAlert={handleExceptionAlert}
                isPlateSampling={isPlateSampling}
                isRawMaterialTest={isRawMaterialTest}
                showToast={showToast}
                setShowToast={setShowToast}
                setToastMessage={setToastMessage}
                setToastType={setToastType}
                toastMessage={toastMessage}
                toastType={toastType}
        />

        {/* scan area model */}
        <ScanAreaModel
            show={showScan}
            onHide={() => setShowScan(false)}
            webCamError={webCamError}
            webCamScan={webCamScan}
        />

        {/* Alert modal for Add Exception */}
        <AlertModal 
          show={showAddExceptionAlert}
          onHide={() => setShowAddExceptionAlert(false)}
          title='Alert'
          message={`Add plates exception`}
          onConfirm={handleExceptionSubmit}
        />

        {/* Alert modal for Add Plate Sampling */}
        <AlertModal 
          show={showPlateSamplingAlert}
          onHide={() => setPlateSamplingAlert(false)}
          title='Alert'
          message={`Add plate sampling`}
          onConfirm={handlePlateSamplingSubmit}
        />

        {/* Alert modal for raw material test */}
        <AlertModal 
          show={showRawMaterialAlert}
          onHide={() => setRawMaterialAlert(false)}
          title='Alert'
          message={`Add raw material test`}
          onConfirm={handleRawMaterialTestSubmit}
        />
    </div>
  )
}

export default AddException;