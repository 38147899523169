import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { workflow } from "../../../../service/workflow";
interface AttributeValue {
  master_process_atributes_mapping_id: string;
  value: string;
  default_value: string | null;
  name: string;
  uom: string | null;
  field_type: string;
  label_name: string;
}

interface ProcessDetail {
  id: string;
  master_process_id: string;
  activity_workflow_definition_id: string;
  sequence: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  process_name: string;
  process_description: string;
  attribute_values: AttributeValue[];
  equipments: any[]; // Assuming this is an array of equipment details
}

interface Workflow {
  id: string;
  name: string;
  description: string;
  master_activity_id: string;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
  activity_name: string;
  process_details: ProcessDetail[];
}

interface ProcessDetails {
  id: string;
  process_name: string;
  attribute_values: Array<{
    master_process_atributes_mapping_id: string;
    label_name: string;
    value: string | null;
  }>;
}

interface WorkflowModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  workflowId: string; // Updated prop to pass workflow ID from parent component
}

const WorkflowModal: React.FC<WorkflowModalProps> = ({
  isOpen,
  onRequestClose,
  workflowId,
}) => {
  const [workflowData, setWorkflowData] = useState<Workflow | null>(null);
  const [selectedProcessId, setSelectedProcessId] = useState<string | null>(
    null
  );
  const selectedProcessDetails = workflowData?.process_details.find(
    (process) => process.id === selectedProcessId
  );

  useEffect(() => {
    const fetchWorkflow = async () => {
      if (workflowId) {
        try {
          const response = await workflow.EditById(workflowId); // Assuming EditById fetches workflow by ID
          if (response.status && response.statusCode === 200) {
            setWorkflowData(response.data);
          } else {
            console.error("Failed to fetch workflow:", response.message);
            setWorkflowData(null); // Reset workflowData on failure
          }
        } catch (error) {
          console.error("Error fetching workflow:", error);
          setWorkflowData(null); // Reset workflowData on error
        }
      }
    };

    if (isOpen && workflowId) {
      fetchWorkflow();
    }
  }, [isOpen, workflowId]);

  return (
    <Modal
      show={isOpen}
      contentLabel="Workflow Modal"
      dialogClassName="custom-modal-workflow"
    >
      <Modal.Header closeButton onClick={() => onRequestClose()}>
        <Modal.Title style={{ display: "flex", padding: 0, fontSize: "20px" }}>{workflowData?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ display: "flex", height: "80vh", padding: 0 }}>
        {workflowData ? (
          <>
            {/* Left Section - List of Process Names */}
            <div
              style={{
                flex: 1,
                borderRight: "1px solid #e0e0e0",
                padding: "10px",
                overflowY: "auto",
                height: "100%", // Ensure full height for scrolling
              }}
            >
              {/* <h5>Process Names</h5>
              <ul style={{ listStyle: "none", padding: 0, margin: 0,  marginTop: "20%"}}>
                {workflowData.process_details.map((process) => (
                  <li
                    key={process.id}
                    className={`process-tab ${
                      process.id === selectedProcessId
                        ? "selected-tab"
                        : "non-selected-tab"
                    }`}
                    onClick={() => setSelectedProcessId(process.id)}
                    style={{
                      padding: "10px",
                      marginBottom: "5px",
                      cursor: "pointer",
                      borderRadius: "5px",
                      backgroundColor:
                        process.id === selectedProcessId ? "#36405D" : "#fff",
                      color: process.id === selectedProcessId ? "#fff" : "#333",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {process.process_name}
                  </li>
                ))}
              </ul> */}
              <h5>Process Names</h5>
<ul
  style={{
    listStyle: "none",
    padding: 0,
    margin: 0,
    marginTop: "10%",
  }}
>
  {workflowData.process_details.map((process, index) => (
    <li
      key={process.id}
      className={`process-tab ${
        process.id === selectedProcessId ? "selected-tab" : "non-selected-tab"
      }`}
      onClick={() => setSelectedProcessId(process.id)}
      style={{
        position: "relative",
        padding: "15px",
        marginBottom: "30px", // Adding space for the arrow effect
        cursor: "pointer",
        borderRadius: "5px",
        backgroundColor: process.id === selectedProcessId ? "#36405D" : "#e9ecf7",
        color: process.id === selectedProcessId ? "#fff" : "#333",
        border: "rgba(1, 1, 1, 0.1) solid",
        fontSize: "14px"
      }}
    >
      {process.process_name}

      {/* Add a downward dotted arrow between items */}
      {index < workflowData.process_details.length - 1 && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            bottom: "-30px",
            width: "2px",
            height: "30px",
            background: "repeating-linear-gradient(180deg, transparent, transparent 2px, #333 2px, #333 4px)",
          }}
        />
      )}
    </li>
  ))}
</ul>
            </div>

            {/* Right Section - Attribute Values */}
            <div
              style={{
                flex: 2,
                padding: "10px",
                overflowY: "auto",
                height: "100%", // Ensure full height for scrolling
              }}
            >
              <h5>Process Details</h5>
              {selectedProcessDetails ? (
                selectedProcessDetails.attribute_values.length > 0 ? (
                  <ul style={{ listStyle: "none", padding: 0, margin: 0, marginTop: "5%" }}>
                    {selectedProcessDetails.attribute_values.map(
                      (attribute) => (
                        <li
                          key={attribute.master_process_atributes_mapping_id}
                          className="attribute-item"
                          style={{
                            paddingBottom: "15px",
                            borderBottom: "1px solid rgba(224, 224, 224, 0.5)",
                            marginBottom: "10px",
                            textAlign: "start",
                            fontSize: "13px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "500",
                              color: "#333",
                              marginBottom: "5px",
                              textAlign: "start",
                              fontSize: "14px",
                            }}
                          >
                            {attribute.label_name}:
                          </div>
                          <div style={{ color: "#555" }}>
                            {attribute.value || "-"}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                ) : (
                  <div>No attributes available for this process</div>
                )
              ) : (
                <div>Select a process to view its attributes</div>
              )}
            </div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </Modal.Body>

      {/* <div className="bottomArea">
          <button onClick={onRequestClose} className="modalCancelBtn">
            Cancel
          </button>
        </div> */}
    </Modal>
  );
};

export default WorkflowModal;
